/**
 * Check If it's a kid route
 * @returns Boolean
 */
const useIsKidsMode = () => {
  const route = useRoute();
  const onKids = computed(() => route.path.includes("kids"));

  return onKids;
};

export default useIsKidsMode;
